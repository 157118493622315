<template>
  <div class="order-inprocess">
    <van-config-provider>
      <van-nav-bar :title="$t('title.expirationTime').format({1: orderTimeout})" left-arrow @click-left="goBack" :border="false"></van-nav-bar>
    </van-config-provider>
    
    <van-tabs v-model:active="tabActive" :type="$AppData.config.themeType ? 'card' : 'line'">
      <van-tab :title="$t('tabTitle.haveInHand')"></van-tab>
      <van-tab :title="$t('tabTitle.expired')"></van-tab>
    </van-tabs>
    <van-pull-refresh v-model="refreshing" @refresh="refreshData">
      <div class="order-wrap">
        <div class="order-list">
          <van-list
            v-if="dataList.length >0"
            v-model:loading="loading"
            :finished="finished"
            :finished-text="$t('common.noMore')"
            @load="loadData"
          >
            <div class="journal-block" v-for="item in dataList" :key="item.sn">
              <van-cell-group :border="false">
                <van-cell :title="$t('field.orderNumber')" :value="item.sn" class="journal-title" value-class="journal-sn"  @click="copy(item.sn)">
                  <template #icon>
                    <van-icon v-if="item.reminder === 2" name="fire" size="20" color="red"/>
                  </template>
                </van-cell>
                <div class="journal-detail"  @click="copy(item.sn)">
                  <van-cell :title="$t('field.amount')" :value="`${item.amountStr} ${$AppData.config.appCoin}`" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
                  <van-cell :title="$t('field.income2')" :value="`${item.incomeStr} ${$AppData.config.appCoin}`" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
                  <!-- <van-cell :title="$t('field.contribution')" :value="item.contribution" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" /> -->
                  <van-cell :title="$t('field.account')" :value="item.qrCodeAccount" :border="false" class="journal-detail-row" title-class="journal-label" />
                  <van-cell :title="$t('field.orderTime')" :value="item.orderTakingTimeStr" :border="false" class="journal-detail-row" title-class="journal-label"/>
                </div>
                <div class="btn-wrap">
                  <van-button v-if="tabActive" type="danger" plain round size="small" @click="handleFailOrder(item)">{{$t('button.fail')}}</van-button>
                  <van-button :type="$AppData.config.themeType ? 'primary' : 'danger'" round size="small" @click="handleDoneOrder(item)">{{$t('button.completed')}}</van-button>
                </div>
              </van-cell-group>
            </div>
          </van-list>
          <div v-else>
            <van-empty :description="$t('common.noData')"></van-empty>
          </div>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Toast } from 'vant';
import useClipboard from 'vue-clipboard3';
import i18n from '@/assets/lang/index';
import { queryOrderProcessingList, queryOrderTimeoutList, queryOrderTimeout, doneOrder, failOrder } from "@/api";

export default {
  setup() {
    const { toClipboard } = useClipboard();
    const copy = async (text) => {
      try {
        await toClipboard(text);
        Toast(i18n.global.t('notify.copySuccess'));
      } catch (e) {
        console.error(e)
      }
    }
    
    const tabActive = ref(0);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const dataList = ref([]);
    return {
      copy,
      tabActive,
      loading,
      finished,
      refreshing,
      dataList,
    };
  },
  
  data() {
    return {
      pageTitle: localStorage.getItem('pageName'),
      current: 1,
      orderTimeout: '',
    };
  },
  
  watch: {
    tabActive() {
      this.refreshing = true;
      this.refreshData();
    }
  },
  
  mounted() {
    this.fetchOrderTimeout();
    this.fetchOrderList();
  },

  methods: {
    fetchOrderList() {
      const doPost = this.tabActive ? queryOrderTimeoutList : queryOrderProcessingList;
      doPost({
        pageSizeStr: 10,
        pageNumberStr: this.current,
      }).then(res => {
        this.dataList = this.dataList.concat(res.data || []);
        this.current = res.pageNumber + 1;
        this.loading = false;
        if (res.totalNumber <= res.pageNumber * res.pageSize) {
          this.finished = true;
        }
      });
    },
    fetchOrderTimeout() {
      queryOrderTimeout().then(res => {
        this.orderTimeout = res.data;
      });
    },
    loadData() {
      if (this.refreshing) {
        this.dataList = [];
        this.current = 1;
        this.refreshing = false;
      }
      this.fetchOrderList();
    },
    refreshData() {
      // 清空列表数据
      this.finished = false;
            
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.loadData();
    },
    handleDoneOrder(order) {
      this.$myDialog.confirm({
        title: i18n.global.t('alert.warnning'),
        message: i18n.global.t('alert.hasReceived').format({
          1: order.qrCodeAccount, 
          2: order.amountStr, 
          3: this.$AppData.config.appCoin,
        }),
      })
      .then(() => {// on confirm
        doneOrder({ sn: order.sn }).then(res => {
          this.refreshing = true;
          this.refreshData();
          this.fetchOrderTimeout();
        });
      })
      .catch(() => {});
    },
    handleFailOrder(order) {
      this.$myDialog.confirm({
        title: i18n.global.t('alert.warnning'),
        message: i18n.global.t('alert.notReceived'),
      })
      .then(() => {// on confirm
        failOrder({ sn: order.sn }).then(res => {
          this.refreshing = true;
          this.refreshData();
          this.fetchOrderTimeout();
        });
      })
      .catch(() => {});
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less">
.theme1 .order-inprocess {
  .van-tabs--card {
    padding-top: 20px;
  }
  .order-wrap {
    margin: 0 16px;
    background: #fff;
    border-radius: 0 0 9px 9px;
    box-shadow: 0px 4px 16px 0px #EBEBEB;
  }
  .order-list {
    padding: 14px;
  }
}
</style>
